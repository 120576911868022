$bg-darker: #111;
$bg-dark: #171717;
$bg-gray: #222;
$fg-light: #ffffff;
$arrow-size: 1rem;

.dark-theme {
    background: $bg-dark;
    color: $fg-light;

    #streamy-header {
        background: $bg-darker;
        color: $fg-light;
    }


    .event-page {
        &__products {
            padding-top: 1rem;
            padding-bottom: 1rem;
            .bg-gray-100 {
                background: $bg-gray;
                border-radius: .25rem;
            }
        }

        .header__content {
            color: #000000;
            padding: 1rem;
        }

        .buy-ticket-button {
            color: $fg-light;
            text-decoration: none;
            text-align: center;
            border-radius: .25rem;
            margin-top: .5rem;
            padding: 0.5rem;
            text-transform: uppercase;
            font-weight: 700;

            &:hover {
                color: white !important;
            }
        }
    }
}

.carousel-root {
    @media (max-width: 768px) {
        height: fit-content;

        .event-hero,
        .event-hero__background .slider,
        .slide {
            height: fit-content;
        }
    }

    height: fit-content;

    .event-hero,
    .slider,
    .slide {
        height: fit-content;
    }

    .control-arrow:hover {
        background: none !important;
    }

    .control-next.control-arrow:before {
        border-left: $arrow-size solid #fff !important;
    }

    .control-prev.control-arrow:before {
        border-right: $arrow-size solid #fff !important;
    }

    .control-arrow:before,
    .carousel.carousel-slider .control-arrow:before {
        border-top: $arrow-size solid transparent !important;
        border-bottom: $arrow-size solid transparent !important;
    }
}

html,
body {
    overscroll-behavior-x: none;
    /* overscroll-behavior-y: none; */
}
