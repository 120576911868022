.event-hero {
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
    padding: 0;

    &__background {
        height: 50vh;
        min-height: 650px;
        max-height: 1200px;
        display: flex;
        justify-content: center;

        @media (max-width: 768px) {
            height: 50vh;
            min-height: 20vh;
        }

        @media (min-width: 768px) {
            img {
                object-fit: cover;
                width: 100%;
            }
        }

        @media (max-width: 768px) {
            img {
                object-fit: cover;
                width: 100%;
            }
        }
    }

    &__heading {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        display: flex;
        align-items: flex-end;
        background: rgba(0, 0, 0, 0.4);
        background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.2) 60%, rgba(0, 0, 0, 0.6));

        &__content {
            margin-bottom: 4rem;

            @media (max-width: 768px) {
                margin-bottom: 1rem;
            }

            width: 100%;
            color: #fff;
            padding: 1rem;
            margin-left: 1rem;
            margin-right: 1rem;
        }

        &-band {
            display: flex;

            & > * {
                display: flex;
                align-items: center;
                width: fit-content;
            }

            @media (min-width: 768px) {
                &::after {
                    content: "";
                    background: url(../../static/band-end.svg) #fff;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: 50%;
                    padding: 0.25rem 0.5rem;
                    background-origin: content-box;
                    display: block;
                    min-width: 125px;
                }
            }
        }
    }
}
