$fader_size: 8rem; // Setting to 0rem will disable fader

.event-scroller {
    @media (min-width: 1800px) {
        max-width: calc(1800px + #{$fader_size});
        overflow: hidden;
    }

    &__wrapper {
        @media (min-width: 1800px) {
            &::before,
            &::after {
                content: "";
                z-index: 10;
                width: $fader_size;
                height: 100%;
                position: absolute;
                top: 0;
            }

            &::before {
                left: 0;
                transform: translateX(-50%);
                background: linear-gradient(90deg, rgba(23, 23, 23, 1) 40%, rgba(23, 23, 23, 0) 100%);
                overflow: hidden;
            }

            &::after {
                right: 0;
                transform: translateX(50%);
                background: linear-gradient(270deg, rgba(23, 23, 23, 1) 40%, rgba(23, 23, 23, 0) 100%);
                overflow: hidden;
            }
        }
    }

    &__content {
        max-width: calc(1800px + #{$fader_size});
        overflow-x: auto;
        cursor: grab;
        user-select: none;

        @media (min-width: 1800px) {
            &>div>:first-child {
                margin-left: calc(#{$fader_size} / 2);
            }

            &>div>:last-child {
                margin-right: calc(#{$fader_size} / 2);
            }
        }
    }
}

.scrollbar-hide {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none !important;
        height: 0 !important;
        width: 0 !important;
        background: transparent !important;
        -webkit-appearance: none !important;
    }
}
