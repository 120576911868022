.nav-item {
    a {
        position: relative;

        &::after {
            content: "";
            height: 2px;
            transition: opacity 0.15s;
            width: 100%;
            background-color: black;
            position: absolute;
            bottom: 5px;
            left: 0;
            opacity: 0;
        }

        &:hover {
            &::after {
                opacity: 100%;
            }
        }
    }

    &.text-white {
        a::after {
            background-color: white;
        }
    }
}
