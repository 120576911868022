.event-card {
    width: 210px;
    height: 340px;
    overflow: hidden;
    display: grid;
    border-radius: 0.25rem;
    border-width: 0px !important;
    border-color: unset !important;
    flex-direction: column;
    grid-template-rows: 1fr max-content;

    &__background {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    @media (min-width: 768px) {
        object-fit: cover;
        width: 400px;
        height: 600px;
    }

    &__info {
        min-height: 20%;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: max-content minmax(3.5rem, max-content) 2rem 2.5rem;
        grid-template-areas:
            "c c s s s s"
            "t t t t t t"
            "d d d d d d"
            "b b b b b b";

        @media (min-width: 768px) {
            // grid-template-rows: 5em 3.5em 3.5em;
            grid-template-rows: 3em 6em 5em;
            grid-template-areas:
                "c c s s s s"
                "t t t t t t"
                "d d d b b b ";
        }

        &__category {
            grid-area: c;
            background: #57ac5b;
            text-align: left;
            width: 100%;
            height: 100%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.125rem;
            align-content: center;

            @media (min-width: 768px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }
        }

        &__subtitle {
            grid-area: s;
            width: 100%;
            height: 100%;
            text-align: left;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.125rem;
            line-height: 1em;

            @media (min-width: 768px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            h2 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__title {
            grid-area: t;
            text-align: left;
            width: 100%;
            height: 100%;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            padding-top: 0.25rem;
            line-height: 1em;
            @media (min-width: 768px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }

            h1 {
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__date {
            grid-area: d;
            justify-content: center;
            background-color: #fff;
            color: black;
            width: 100%;
            height: 100%;
            padding-left: 0.5rem;
            @media (min-width: 768px) {
                padding-left: 1rem;
            }

            .icon {
                width: 1.5em;
                height: 1.5em;
            }
        }

        &__buy-ticket {
            grid-area: b;
            place-items: center;
            justify-content: center;
            font-size: 0.6rem;

            .icon {
                height: 0.8em;
            }
        }
    }
}
