$astrid-pink: #f36897;
$astrid-yellow: #f1de30;

@font-face {
    font-family: astridsFont;
    src: url(../static/astrids/Adieu-Bold.otf);
}

#streamy-header.whitelabel {
    background: $astrid-pink;
}

.astrids--has-stream {
    background-position-y: 0em !important;

    .spotify-holder {
        width: 40%;
    }
}

.astrids {
    background-color: $astrid-yellow;
    font-family: astridsFont, monospace;
    color: $astrid-pink;
    background-image: url("/static/astrids/swirly-v1.svg");
    background-position-y: 10em;
    background-size: 100%;
    background-repeat: no-repeat;

    .astrids-portrait {
        background-color: $astrid-yellow;
        width: 50%;
        float: right;
    }

    .spotify-logo {
        width: 50%;
        float: right;
    }

    .text-background-color {
        background-color: $astrid-yellow;
    }

    .regular-text {
        font-family: acumin-pro, sans-serif;
        font-size: 1.2em;
    }

    .alert {
        background-color: $astrid-pink;
        color: $astrid-yellow;
    }

    .astrids-chat {
        background-color: $astrid-yellow;
    }

    .chat-item-self {
        background-color: #fcfcfc;
        color: black;
    }

    .chat-item-other {
        background-color: $astrid-pink;
        color: black;
    }

    .chat-item {
        font-family: acumin-pro, sans-serif;
    }

    .bg-brand-green-tertiary {
        background-color: #fcfcfc;
        color: black;
    }

    .bg-brand-blue-tertiary {
        background-color: $astrid-pink;
        color: black;
    }

    .chat {
        input {
            font-family: acumin-pro, sans-serif;
        }
    }

    button {
        background-color: $astrid-pink;
        color: $astrid-yellow;
        border: 1px solid $astrid-yellow;

        &:hover {
            background-color: #6429f5;
        }
    }

    h1 {
        font-family: astridsFont, monospace;
    }

    h2 {
        font-family: astridsFont, monospace;
    }

    input {
        min-width: 10rem;
    }
}

@media (max-width: 1200px) {
    .astrids--has-stream {
        background-position-y: 15vw !important;
    }

    .spotify-logo {
        margin-left: 2vw;
    }
}

@media (max-width: 768px) {
    .astrids--has-stream {
        background-position-y: 90vw !important;
        .spotify-holder {
            width: 100%;
        }
    }

    .astrids {
        background-position-y: 120vw;

        &__player {
            margin-left: -1rem;
            margin-right: -1rem;
        }

        .astrids-portrait {
            width: 100%;
        }

        .spotify-logo {
            background-color: #{$astrid-yellow}f5;
            margin-top: 2rem;
            width: 80%;
        }

        .alert {
            margin-right: -1rem;
            margin-left: -1rem;
        }
    }
}
