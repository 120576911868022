header {
    nav {
        .login-button {
            &::after {
                content: "";
                background: url(./static/ribbon-ending-green.svg);
                display: inline-block;
                position: absolute;
                height: 60px;
                height: 100%;
                width: 0.5rem;
                right: -0.5rem;
                background-position: 0;
                background-repeat: no-repeat;
                background-size: contain;
            }
        }
    }
}

.min-heigth-80vh {
    min-height: 70vh;
}

.modal {
    transition: opacity 0.25s ease;
}
body.modal-active {
    overflow-x: hidden;
    overflow-y: visible !important;
}
