h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Mongoose", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    clear: both;
    font-weight: bold;
}
