$bg-dark: #171717;
$paddingX: 1.5rem;
$paddingY: .75rem;

.carousel-event {
    width: 100%;
    height: fit-content;
    margin-bottom: 2rem;
    position: relative;
    padding: 0;

    &__background {
        height: 100%;
        min-height: 650px;
        max-height: 1200px;
        display: flex;
        justify-content: center;
        place-content: center;
        background-position: center;
        background-size: cover;

        @media (max-width: 768px) {
            height: 50vh;
            min-height: 20vh;
        }
    }

    &__heading {
        pointer-events: none;
        &__content {
            width: 100%;
            color: #fff;
            display: grid;
            align-content: center;
            grid-template-columns: 8rem max-content repeat(9, 1fr) 11rem;
            // grid-template-rows: 1fr 1fr 1fr;
            position: absolute;
            bottom: 0;
            grid-template-areas:
                "c s . . . . . . . . . ."
                "t t . . . . . . . . . ."
                "d d . . . . . . . . . b";

            padding: 1rem 2rem;

            @media (max-width: 768px) {
                position: initial;
                height: fit-content;
                padding: 0;
                margin: 0;
                width: 100%;
                display: grid;
                background: $bg-dark;
                grid-template-columns: repeat(6, 1fr);
                grid-template-rows: none;
                grid-template-areas:
                    "c c s s s s"
                    "t t t t t t"
                    "d d d b b b";
            }

            &__category {
                grid-area: c;
                background: #57ac5b;
                text-align: left;
                width: 100%;
                height: 100%;
                padding-top: $paddingY+0.125rem;
                padding-bottom: $paddingY;
                padding-left: $paddingX;
                line-height: 1em;
            }

            &__subtitle {
                grid-area: s;
                min-width: 15vw;
                width: 100%;
                height: 100%;
                text-align: left;
                padding: $paddingY+0.125rem $paddingX $paddingY;
                line-height: 1em;

                h2 {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            &__title {
                grid-area: t;
                text-align: left;
                width: 100%;
                height: 100%;
                font-size: 3.5rem;
                line-height: 1em;
                padding: $paddingY $paddingX;
                h1 {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }

            &__date {
                grid-area: d;
                background-color: #fff;
                color: $bg-dark;
                width: 100%;
                height: 100%;

                .icon {
                    width: 1.5em !important;
                    height: 1.5em !important;
                    margin-left: $paddingX;
                    margin-right: calc(#{$paddingX} / 2);
                }

                &__band-end {
                    img {
                        width: auto !important;
                    }
                }
            }

            &__buy-ticket {
                grid-area: b;
                place-items: center;
                border-radius: 0.125rem !important;
                padding: $paddingY/2;
                pointer-events: all;

                .icon {
                    height: 1em;
                }
            }

        }
    }
}
